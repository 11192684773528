import {SERVER_URL} from "../config";
import {PaperClipIcon} from "@heroicons/react/20/solid";

export default function({files}) {
  return (
    <div className="flex flex-col">
          <h2 className="text-xl font-bold leading-7 text-black">결과</h2>
          <div className="flex flex-col tems-start pt-4">
            {files.map(({name, url}) => (
              <a href={SERVER_URL + url} download className="flex text-md font-normal leading-5 text-blue-500 pt-1.5 hover:underline">
                <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                <div>{name}</div>
              </a>
            ))}
          </div>
        </div>
  )
}