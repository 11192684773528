export default function ({className}) {
  return (
    <svg className={className} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <rect x="-1" width="36" height="36" fill="url(#cleansing-icon-pattern0)"/>
      <defs>
        <pattern id="cleansing-icon-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_121_1126" transform="scale(0.0078125)"/>
        </pattern>
        <image id="image0_121_1126" width="128" height="128"
               xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABmJLR0QA/wD/AP+gvaeTAAAEIUlEQVR4nO3dSYgcVRzH8e9EcSEuiUQ0ggaUSTAgiHjNRb1JToJKwBVXEFEvGjx7NBERIcHDmINGjB5ERNCg4sGDJw8uubhgVHCNZkyMMxM9VALDMF2vXnd1/6vrfT9Qp9f9rzfVv6nqflX1CiRJkiRJkiRJkiRJkiRJkiRJkiRJkjQ1ZqI7sMKlwE3AZuCs4L607V/gK+B94OfgvnTOLHAAWAT+6/myAOwHrmply/XADuAY8R/MpJd54LYWtt9U2wEsEf9hRC1LFByCWcr8z19tT3DliNtyKh0gfuN3ZXl1xG05tKhfARuB74EzgtbfNYvAZcAvk17xmkmv8JQb8cNf7kzghqgVR9iSaN8FvEi1e2ziIuDTmvb9wNMNa532DHB7Tfv1wB8Na80AjwCP1bzm6oa1WhUVgLWJ9heAbzLqHU20/wV8nVHv9HvqfAf8mlHveeoDcEFGrdZEHQJSTkZ3YAw6+Td1NQCaEANQOANQOANQOANQOANQOANQOANQuK4GIPckVdMhY60QNRT8d6L9AarzAUsN661LtJ8NrG9Ya/l7Uuts2j+ACzPX32t3EH8OvmvLrpG26JCiDgEHyfvv0ZhEBeBH4K2gdWuZyC+BO4F/AtcvYgNwCHgQv8GHiv4ZuA+4FzgR3I9iRQcAYA64Dnibjl400WdR4wArfQFsB66gujdwK3B+xvvPAe6saf8S+DizT9uov05vH3nfYc6juhFGY3Ax9b+x9wxRc0+i5obMepsS9YoaB1BHGIDCGYDCGYDCGYDCGYDCGYDCGYDCGYDCGYDC9SUAi4n2jRPpxRSKOhl0K9VJn0GeAn7PqPcncBw4d0D7dqorkH7KqLkt0f4s+SeDdMpu6k+MbBqi5keJml1fPBk0oteiOzCN+hSAOeCH6E5Mmz4F4BhwH15VlKVPAQB4F3gYQ9BY3wIAsBe4GTgc3ZFp0McAQLUn2Ey1N/iA6vCgVURNFbuWwb/ZoRoDaHs3vo68v3c3cFdN+yzwW0a9y4HPEut7IqNeKyLvDk7dIdy2I5mvT92rcITmM4VC0ESQKX09BKghA1A4A1A4A1A4A1A4A1A4A1A4A1A4A1A4AzC83G3XyYdkRQ0Fr6d+aPQwedPIraEaax/kKHnXGEJ6qPpx4CWazXE0AzyaeE3qGUW90vY1gRsS9fYO0cf7EzXbXkIeIeshYLD3mNwMZgtUk2dOnAEY7FvgnQmt63XyHkHXGgNQ70nGP4XdPPkPtWyNAaj3OfAQ4zsUnATuptrbhDAAaXPAPbQ/re081R1Sb7RcN4sBaOZl4FrgTUaf5XwBeAW4huAPH+LGAT5MtOf+Jj4OPFfT/klmvdUcAm4BLqF6+vkW0g+VWO4E1YSYBwn6widJkiRJkiRJkiRJkiRJkiRJkiRJkqQp9z8YvnYzlDwwOAAAAABJRU5ErkJggg=="/>
      </defs>
    </svg>
  )
}