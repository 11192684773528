import FileUpload from "./FileUpload";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {MagnifyingGlassIcon, TrashIcon} from "@heroicons/react/20/solid";
import DownloadableFiles from "./DownloadableFiles";
import _ from "lodash";

const SubMenuType = {
  DEDUP_COLUMNS: 0,
  COLUMN_CLEAR: 1,
}
Object.freeze(SubMenuType)

function DedupColumnForm({value, onChange}) {
  return (
    <>
      <h2 className="text-lg font-semibold">중복 컬럼</h2>
      <div className="mt-2">
        <textarea
          rows={4}
          name="comment"
          id="comment"
          className="block w-full rounded-md border-0 py-1.5 h-16 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={value}
          onChange={(e) => {
            onChange(e.target.value)
          }}
        />
      </div>
    </>
  )
}

function ColumnClearForm({rows, onChange, onDelete}) {
  return (
    <>
      <div className="flex items-center gap-4 mb-2">
        <h2 className="flex text-lg font-semibold">컬럼 재정리</h2>
        <div className="cursor-pointer text-md text-blue-500 hover:underline" onClick={() => {
          rows = [...rows, {key: "", unit: ""}]
          onChange(rows)
        }}>추가
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {
          rows.map(({key, unit}, idx) => (
            <div className="flex flex-row" key={idx}>
              <div className="flex flex-col w-9/12 pr-2">
                <input
                  type="text"
                  className="block text-sm rounded-md ring-indigo-400 border-gray-300 border-1 px-2 h-8"
                  value={key}
                  placeholder="RPM_%"
                  onChange={(e) => {
                    onChange(rows.map((r, i) => {
                      if (i === idx) {
                        return {...r, key: e.target.value}
                      } else {
                        return r
                      }
                    }))
                  }}
                />
              </div>
              <div className="flex flex-col w-2/12">
                <input
                  type="text"
                  className="text-sm rounded-md ring-indigo-400 border-gray-300 border-1 px-2 h-8 w-full"
                  placeholder="rpm"
                  value={unit}
                  onChange={(e) => onChange(rows.map((r, i) => {
                    if (i === idx) {
                      return {...r, unit: e.target.value}
                    } else {
                      return r
                    }
                  }))}
                />
              </div>
              <div className="flex flex-col w-1/12">
                <TrashIcon className="text-gray-300 w-5 my-auto mx-2 hover:text-gray-400 cursor-pointer" onClick={() => {
                  onDelete(idx)
                }}/>
              </div>
            </div>
          ))
        }
      </div>
    </>
  )
}

function SubMenu({
                   subMenuType,
                   columnNamesForDedup, setColumnNamesForDedup,
                   columnKeysForClear, setColumnKeysForClear,
                 }) {
  switch (subMenuType) {
    case SubMenuType.DEDUP_COLUMNS:
      return <DedupColumnForm value={columnNamesForDedup} onChange={setColumnNamesForDedup}/>
    case SubMenuType.COLUMN_CLEAR:
      return <ColumnClearForm rows={columnKeysForClear} onChange={setColumnKeysForClear} onDelete={(idx) => {
        setColumnKeysForClear(_.filter(columnKeysForClear, (_, i) => {
          return i !== idx
        }))
      }}/>
    default:
      return <></>
  }
}

export default function Cleansing({openToast, confirmPresetName}) {
  const [files, setFiles] = useState(null)
  const [subMenuType, setSubMenuType] = useState(SubMenuType.DEDUP_COLUMNS)
  const [columnNamesForDedup, setColumnNamesForDedup] = useState("")
  const [downloadableFiles, setDownloadableFiles] = useState([])
  const [isDedup, setIsDedup] = useState(false)
  const [isClear, setIsClear] = useState(false)
  const [progress, setProgress] = useState(false)
  const [presets, setPresets] = useState([])
  const [columnKeysForClear, setColumnKeysForClear] = useState([])
  const [searchPrefix, setSearchPrefix] = useState("")

  const savePreset = async () => {
    const {name, ok} = await confirmPresetName("")
    if (!ok) {
      return
    }
    const url = '/api/cleansing/presets'
    const method = 'POST'

    const req = {
      name,
      'column_names_for_dedup': [],
      'is_dedup': isDedup,
      'is_clear': isClear,
      'column_keys_for_clear': [],
      'column_units_for_clear': [],
    }

    if (isDedup) {
      for (const col of columnNamesForDedup.split(",").map((t) => t.trim())) {
        for (let i = 0; i < col.length; ++i) {
          const ch = col.charAt(i)
          if (!ch.match(/[a-zA-Z]/i)) {
            openToast("\"중복 컬럼\"은 알파벳과 콤마로만 작성할 수 있습니다.")
            return
          }
        }
        req["column_names_for_dedup"].push(col)
      }
    }

    if (isClear) {
      for (const col of columnKeysForClear) {
        if (col.key === "" && col.unit === "")
          continue
        req["column_keys_for_clear"].push(col.key)
        req["column_units_for_clear"].push(col.unit)
      }
    }

    const resp = await fetch(url, {
      method: method,
      body: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (resp.status !== 200) {
      const exc = await resp.json()
      openToast(exc.detail)
      return
    }

    await searchPresets("")
  }

  const removePreset = async (id) => {
    const resp = await fetch(`/api/cleansing/presets/${id}`, {
      method: "DELETE",
    })
    if (resp.status !== 200) {
      const body = await resp.json()
      openToast(body.detail)
    }
    await searchPresets(searchPrefix)
  }

  const searchPresets = async (prefix) => {
    const resp = await fetch(`/api/cleansing/presets?name=${prefix}`)
    if (resp.status !== 200) {
      const e = await resp.json()
      openToast(e.detail)
      return
    }

    const presets = await resp.json()
    setPresets(presets)
    setSearchPrefix(prefix)
  }

  const loadPreset = async (presetId) => {
    const resp = await fetch(`/api/cleansing/presets/${presetId}`)
    if (resp.status !== 200) {
      const exc = await resp.json()
      openToast(exc.detail)
      return
    }

    const preset = await resp.json()
    setIsDedup(preset.is_dedup)
    setIsClear(preset.is_clear)
    setColumnNamesForDedup(preset.column_names_for_dedup.join(","))
    setColumnKeysForClear(_.zip(preset.column_keys_for_clear, preset.column_units_for_clear).map(([key, unit]) => {
      return {key, unit}
    }))
  }

  const clearPreset = () => {
    setIsDedup(false)
    setIsClear(false)
    setColumnNamesForDedup([])
    setColumnKeysForClear([])
    setFiles(null)
  }

  const subMenuTabs = [{
    label: '중복 컬럼 제거',
    type: SubMenuType.DEDUP_COLUMNS,
    isPerform: isDedup,
    setIsPerform: setIsDedup,
  }, {
    label: '컬럼 정리',
    type: SubMenuType.COLUMN_CLEAR,
    isPerform: isClear,
    setIsPerform: setIsClear,
  }]

  useEffect(() => {
    async function doIt() {
      const resp = await fetch("/api/cleansing")
      const respJson = await resp.json()
      setDownloadableFiles(respJson)

      await searchPresets("")
    }

    doIt().catch((err) => console.error(err))
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      setProgress(true)
      const body = new FormData()

      if (files == null) {
        openToast("업로드할 파일을 지정하십시오.")
        return
      }
      for (const f of files) {
        body.append("file", f)
      }

      if (!isDedup && !isClear) {
        openToast("\"중복 컬럼 제거\" 또는 \"컬럼 정리\"를 체크해주세요.")
        return
      }
      body.set("is_dedup", isDedup)
      body.set("is_clear", isClear)


      body.append("column_name_for_dedup", "")
      if (isDedup) {
        if (columnNamesForDedup === "") {
          openToast("\"중복 컬럼\"을 작성해주세요.")
          return
        }

        for (const col of columnNamesForDedup.split(",").map((t) => t.trim())) {
          for (let i = 0; i < col.length; ++i) {
            const ch = col.charAt(i)
            if (!ch.match(/[a-zA-Z]/i)) {
              openToast("\"중복 컬럼\"은 알파벳과 콤마로만 작성할 수 있습니다.")
              return
            }
          }
          body.append("column_name_for_dedup", col)
        }
      }

      body.append("column_key_for_clear", "")
      body.append("column_unit_for_clear", "")
      if (isClear) {
        for (const col of columnKeysForClear) {
          if (col.key === "" && col.unit === "")
            continue
          body.append("column_key_for_clear", col.key)
          body.append("column_unit_for_clear", col.unit)
        }
      }

      const resp = await fetch("/api/cleansing", {
        method: "POST",
        body,
      })
      const respBody = await resp.json()
      if (resp.status !== 200) {
        openToast(respBody.detail)
        return
      }

      setDownloadableFiles(respBody)
      openToast("작업이 성공적으로 완료되었습니다.", false)
    } finally {
      setProgress(false)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="space-y-12 container max-w-4xl">
          <div className="border-b border-gray-900/10 pb-12 w-9/12">
            <div className="flex justify-end">
              <button type="button" className="text-sm text-white font-normal bg-red-500 px-4 py-1 rounded-md hover:bg-red-400" onClick={() => {
                clearPreset()
              }}>Clear
              </button>
            </div>
            <FileUpload files={files} setFiles={setFiles}/>
          </div>


          <div className="flex flex-row gap-8">
            <div className="flex flex-col w-6/12">
              <div className="flex gap-4 mb-10">
                {subMenuTabs.map(({label, type, isPerform, setIsPerform}) => (
                  <span key={`sub-menu-tab-${type}`} className={classNames(
                    "flex px-6 py-2 items-center bg-gray-100 focus:bg-indigo-300 hover:bg-indigo-300 cursor-pointer rounded-md border-2 hover:border-transparent",
                    {
                      "bg-indigo-300 border-transparent": subMenuType === type,
                      "bg-gray-100": subMenuType !== type,
                    },
                  )} onClick={() => {
                    setSubMenuType(type)
                  }}>
                    <input type="checkbox"
                           id="dedup-columns"
                           className="px-2 py-2 rounded border-gray-600 text-indigo-600 focus:ring-transparent focus:ring-offset-0 bg-transparent"
                           checked={isPerform}
                           onChange={() => setIsPerform(!isPerform)}
                    />
                    <div className="ml-2 font-medium text-sm">{label}</div>
                  </span>
                ))}
              </div>
              <div className="flex flex-col">
                <SubMenu
                  subMenuType={subMenuType}
                  columnNamesForDedup={columnNamesForDedup}
                  setColumnNamesForDedup={setColumnNamesForDedup}
                  columnKeysForClear={columnKeysForClear}
                  setColumnKeysForClear={setColumnKeysForClear}
                />
                <div className="flex mt-6 items-center justify-end gap-x-2">
                  <button
                    type="button"
                    className="rounded-md border-blue-600 border-2 px-4 py-1 text-sm font-semibold text-blue-600 shadow-sm hover:border-blue-400"
                    onClick={savePreset}
                  >
                    프리셋 저장
                  </button>
                  <button
                    type="submit"
                    className="inline-flex rounded-md bg-blue-600 border-2 border-transparent px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-blue-300"
                    disabled={progress}
                  >
                    {progress ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> : <></>}
                    실행
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-6/12">
              <div className="flex gap-4">
                <h2 className="my-auto text-md font-semibold">Preset</h2>
                <div className="w-full my-auto flex rounded-md shadow-sm">
                  <div className="flex flex-grow items-stretch focus-within:z-10">
                    <input
                      type="text"
                      className="inline-flex w-full rounded-none rounded-l-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Search"
                      onChange={(e) => searchPresets(e.target.value)}
                    />
                  </div>
                  <div
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300"
                  >
                    <MagnifyingGlassIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                  </div>
                </div>
              </div>

              <div className="flex flex-col px-8 py-2 mt-2 gap-2 w-full h-64 border-2 rounded border-gray-200">
                {presets.map(({id, name}) => (
                  <div key={id} className="flex items-center">
                    <div className="flex text-base font-normal leading-5 w-11/12 cursor-pointer" onClick={() => {
                      loadPreset(id)
                    }}>{name}</div>
                    <div className="flex">
                      <TrashIcon className="cursor-pointer h-4 w-4 text-gray-400" onClick={() => removePreset(id)}/>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <DownloadableFiles files={downloadableFiles}/>
        </div>
      </form>
    </>
  )
}