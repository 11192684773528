export default function ({className}) {
  return (
    <svg className={className} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <rect x="-1" width="36" height="36" fill="url(#denoising-icon-pattern0)"/>
      <defs>
        <pattern id="denoising-icon-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#denoising-icon-img0" transform="scale(0.0078125)"/>
        </pattern>
        <image id="denoising-icon-img0" width="128" height="128"
               xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABmJLR0QA/wD/AP+gvaeTAAAD8klEQVR4nO3dPYgcZRzH8e95anL4EoNGOIyKQYlgUhgxmCK+FGl8IUaNEC9NChXBIpgmrUGrECxsRASbCGphoaCWCqKFb5CDgEERJI2CJHInSnyJxTNys5vN7e2ze/Ps7P/7gYGZvXn+z3O3v91nbmZuDyRJkiRJkiRJkiRJkiRJkiRJkiRJUptMFep3N3B7ob7H1Ungg9KDaMJu4LxLz+WhIX6uWS5pukN85S9nS9MdXtp0h3ROO2eBrzJqrAF21rY/A/7MqHM3sK5aPwX8lFHjZuC2av034MsB298FrK/WS03JjTrM0lvep5k1NtL51nljZp0vajVeyKxxqFbj84z2n9TaH84cQ7YSU4DGiAEIzgAEZwCCMwDBGYDgDEBwBiA4AxCcAQjOAARX4mJQd//r++51oXU9thcz+//fTOZYZrrqDVqj9HPQuPrFIJfOxYtBapYBCK70/PMt8HRGu+uBD2vbDwK/ZNR5E9harb8CvJVRYz9wsFqfBw4M2P51YFtGvyNROgALwNcZ7TZ2bc8DpzPq/F5bP505lvtr64sZNRYy+hwZp4DgDEBZe4A7Sw6g9BQwaa4EHgFmq+2zwPekKeqv2n5XAMeAZxsdXQ8GYLS2Au/3ePwP0p3LHwM/A0eATQ2O66IMwHCmWNkR/Aywq1rGiscAw3kJeKr0IIZhAPLtoMCp21GLNAVsAp4kPXE3AH8z3J+pvcwEvIAiBGAWOArsY/knbAfpKH4lVxU3Aw8MP7TyWp/gPnYBJ4A5+n+vT1T7bl9B3UeHHNfYmOQAzJGuF1w3QJtbSH+v+Fif/Xb2+XprlJ4CZoFnMtp133QxB5ypbW8AXgSmM2qvBd6tar7T4+vTwD0ZdVVpyw0h50hXGbvdu4p9ekPIGLkMeI/0TlD3fIGxrJrSU8Ai8F1Gu8tZuo4P6Vz7OdKvejn39V3MGuA46Z3gVdIB4t4R1g9ptT4g4lrScUDpqcMpoJBDwDWlB9E2paeAtXReFVsAfgX+HbDOVcBzoxpUJKUDsB34oeuxf0gf1vQN8BHwNp23bvUyh6/+LOM4BUyTTsg8DrwB/Eg6S7ec/as9qEk1jgHotoF0YmbfMvvc1NBYJk4bAgDpxovXWPo8Po1IWwIAcDXp3H73LeEaQpsCAHAr6cMY7ys9kEnRtgBAOulzvPQgJkUbA6ARMgDBGYDgDEBwBiA4AxCcAQjOAARnAIIzAMEZgOAMQHAGIDgDEJwBCM4ABGcAgjMAwRmA4AxAcAYgOAMQXIkAnC/QZ1s0/rMpEYCTBfpsixNNdzjVdIeVh4E7CvU9rubp/Dc4kiRJkiRJkiRJkiRJkiRJkiRJkiRJF/gPaGptl3VuVDsAAAAASUVORK5CYII="/>
      </defs>
    </svg>
  )
}