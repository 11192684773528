import {ArchiveBoxIcon, PaperClipIcon} from "@heroicons/react/20/solid";

function UploadForm({files, setFiles}) {
  return (
    <label className="cursor-pointer mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" htmlFor="file">
      <div className="col-span-full">
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 bg-gray-50">
          <div className="text-center">
            <ArchiveBoxIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <div
                className="relative rounded-md font-semibold text-zinc-600"
              >
                <span>Click or drag file to this area to upload</span>
                <input id="file" name="file" type="file" className="sr-only" multiple onChange={(e) => {
                  setFiles(e.target.files)
                }}/>
              </div>
            </div>
            <p className="text-xs leading-5 text-gray-400">파일 업로드</p>
          </div>
        </div>
      </div>
    </label>
  )
}

function UploadedFiles({fileList}) {
  let files = []
  for (let i = 0; i < fileList.length; ++i) {
    files.push(fileList[i])
  }

  return (
    <div className="flex flex-col">
      <h2 className="text-xl font-bold leading-7 text-black">파일 업로드</h2>
      <div className="flex flex-col tems-start pt-4">
        {files.map((f) => (
          <span className="flex text-md font-normal leading-5 text-blue-500 pt-1.5">
            <PaperClipIcon className="h-5 w-5" aria-hidden="true"/>
            <div>{f.name}</div>
          </span>
        ))}
      </div>
    </div>
  )
}

export default function FileUpload({files, setFiles}) {
  if (files == null) {
    return <UploadForm files={files} setFiles={setFiles}/>
  } else {
    return <UploadedFiles fileList={files}/>
  }
}