import {PaperClipIcon, ArchiveBoxIcon} from "@heroicons/react/20/solid";
import {useEffect, useState} from "react";
import {SERVER_URL} from "../config";
import FileUpload from "./FileUpload";
import DownloadableFiles from "./DownloadableFiles";

export default function Denoising({openToast}) {
  const [files, setFiles] = useState(null)
  const [smoothingFactor, setSmoothingFactor] = useState(0.9)
  const [downloadableFiles, setDownloadableFiles] = useState([])
  const [progress, setProgress] = useState(false)

  const onSubmit = async (e) => {
    e.stopPropagation()
    e.preventDefault()

    setProgress(true)
    try {
      const formData = new FormData()
      for (const f of files) {
        formData.append("file", f)
      }
      formData.set("smoothing_factor", smoothingFactor)

      const resp = await fetch("/api/denoising", {
        method: "POST",
        body: formData,
      })

      const downloadableFiles = await resp.json()
      setDownloadableFiles(downloadableFiles)
      openToast("작업이 성공적으로 완료되었습니다.", false)
    } finally {
      setProgress(false)
    }
  }

  useEffect(() => {
    async function doIt() {
      const resp = await fetch("/api/denoising")
      const respData = await resp.json()
      setDownloadableFiles(respData)
    }

    doIt().catch((err) => console.error(err))
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-12 container max-w-4xl">
        <div className="border-b border-gray-900/10 pb-12 w-9/12">
          <FileUpload files={files} setFiles={setFiles}/>
        </div>

        <div className="flex flex-col w-6/12">
          <h2 className="text-xl font-bold leading-7 text-black">설정</h2>
          <div className="grid grid-cols-5 tems-start pt-4">
            <label htmlFor="smoothing_factor" className="block text-sm font-normal leading-5 text-gray-900 sm:pt-1.5">
              Smoothing Factor
            </label>
            <div className="mt-2 col-span-1 mt-0">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-2/3">
                <input
                  type="number"
                  name="smoothing_factor"
                  id="smoothing_factor"
                  autoComplete="0.9"
                  value={smoothingFactor}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-3 w-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="number"
                  onChange={(e) => {
                    setSmoothingFactor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex text-sm font-normal leading-5 text-gray-400 mt-1">
            0부터 1까지 소수점 둘째자리 수(default: 0.9)
          </div>
          <div className="flex mt-6 items-center justify-end gap-x-6 w-10/12">
            <button
              type="submit"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 disabled:bg-indigo-300"
              disabled={progress}
            >
              {progress ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg> : <></>}
              실행
            </button>
          </div>
        </div>


        <DownloadableFiles files={downloadableFiles}/>
      </div>
    </form>
  )
}
