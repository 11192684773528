import {useState} from "react";
import Site from "./Site";
import Denoising from "./Denoising";
import Cleansing from "./Cleansing";
import PresetNameDialog from "./PresetNameDialog";

export default function App() {
  const [navKey, setNavKey] = useState("data_cleansing")
  const [alert, setAlert] = useState({show: false, message: "", error: false})
  const [presetNameDialog, setPresetNameDialog] = useState({
    show: false,
    name: "",
    onOK: ({name}) => {},
    onCancel: () => {},
  })

  const confirmPresetName = (name) => {
    return new Promise((resolve, reject) => {
      setPresetNameDialog({
        show: true,
        name: name,
        onOK: ({name}) => {
          setPresetNameDialog({...presetNameDialog, show: false})
          resolve({name, ok: true})
        },
        onCancel: () => {
          setPresetNameDialog({...presetNameDialog, show: false})
          resolve({name: "", ok: false})
        }
      })
    })
  }

  const openToast = (message, error) => {
    error = error??true
    setAlert({show: true, message, error})
  }

  if (navKey === "data_cleansing") {
    return (
      <>
        <PresetNameDialog show={presetNameDialog.show} placeholderName={presetNameDialog.name} onOK={presetNameDialog.onOK} onCancel={presetNameDialog.onCancel} />
        <Site navKey={navKey} setNavKey={setNavKey} alert={alert} setAlert={setAlert}>
          <Cleansing openToast={openToast} confirmPresetName={confirmPresetName}/>
        </Site>
      </>
    )
  } else if (navKey === "noise_deletion") {
    return (
      <Site navKey={navKey} setNavKey={setNavKey} alert={alert} setAlert={setAlert}>
        <Denoising openToast={openToast}/>
      </Site>
    )
  } else {
    return null
  }
}
