import {
  InboxIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline'
import {useState} from "react";
import {classNames} from "../utils";
import CleansingIcon from "../icons/CleansingIcon";
import DenoisingIcon from "../icons/DenoisingIcon";

const navigation = [
  {name: '데이터 정리', href: '#', icon: CleansingIcon, key: "data_cleansing"},
  {name: '노이즈 제거', href: '#', icon: DenoisingIcon, key: "noise_deletion"},
]

const navigation2 = [
  {name: '가이드', href: '#'},
]

export default function Sidebar({navKey, setNavKey}) {
  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-5 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          &nbsp;
          <div className="flex h-16 shrink-0 items-center justify-center">
            <h1 className="flex text-4xl">CNU Data</h1>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-4 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        onClick={() => {
                          setNavKey(item.key)
                        }}
                        href={item.href}
                        className={classNames(
                          item.key === navKey
                            ? 'bg-gray-50 text-indigo-600'
                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                            'h-9 w-9 shrink-0'
                          )}
                        />
                        <span className="-mx-3">{item.name}</span>
                      </a>
                    </li>
                  ))}
                  <hr className="mx-2"/>
                  {navigation2.map((item) => (
                    <li key={item.name} className="hover:bg-gray-50 px-9 text-gray-700 hover:text-indigo-600">
                      <a
                        href={item.href}
                        className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        {item.name}
                      </a>
                    </li>
                    ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}
